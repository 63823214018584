import { formatCurrencyString, useShoppingCart } from "use-shopping-cart";
import Image from 'next/image';
import { Button, Col, Row } from "react-bootstrap";
import { MinimumArtWork } from "./ap-modal";

interface CartItemProps {
  item: MinimumArtWork;
}

export default function CartItem({item}: CartItemProps) {
  
  const {addItem, removeItem, cartDetails} = useShoppingCart();

    if(!item) {
        return (
            <>
                Underlying product data is empty...
            </>
        );
    }

    const removeItemFromCart = () => {
      if(!cartDetails) 
        return;

      // 1. get the priceItem from the cart
      const priceItem = cartDetails[item.price_id];
      if (!priceItem)
        return;

      const artworks = priceItem.items;
      if (!artworks || artworks.length === 0) 
        return;

      const updatedArtworks = artworks.filter((artwork : MinimumArtWork) => artwork.id !== item.id);
      // 2. if the current priceItem['items'] is empty, remove the priceItem from the cart
      if (updatedArtworks.length === 0) {
        removeItem(item.price_id);
        return;
      } else {
        // 3. remove the artwork from the priceItem['items']
        const newPriceItem = {...priceItem};
        newPriceItem.items = updatedArtworks;

        // The reason why we need to remove the priceItem entirely before adding a new artwork is
        // because the before and after priceItem has the same price_id, so the useShoppingCart() will not
        // detect any changes and will not update the cartDetails.
        removeItem(item.price_id);
        addItem(newPriceItem, {count: newPriceItem.quantity - 1});
      }

    }

    return (
        <Row className="m-2 align-items-center">
          <Col className="text-dark">

            <Image alt={item.alt_description ?? ''} src={item.url} width={50} height={50} className="rounded-3" />
          </Col>
          <Col className="text-dark">{item.purchaseKey}</Col>
          <Col className="ml-auto text-dark">
            {formatCurrencyString({ value: item.price, currency: "AUD" })}
          </Col>
          <Col>
            <Button onClick={() => removeItemFromCart()} className="btn btn-light">
              <Image alt="delete icon" src="/imgs/trash.svg" width={20} height={20} />
            </Button>
          </Col>
      </Row>
    );
}