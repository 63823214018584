'use client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
// import type { Metadata } from 'next'
import { Inter } from 'next/font/google'
import './globals.css'
import APNavbar from '@/components/ap-navbar';
import Footer from '@/components/ap-footer';
import { CartProvider, DebugCart } from 'use-shopping-cart';
import { useEffect } from 'react';

const inter = Inter({ subsets: ['latin'] })

// export const metadata: Metadata = {
//   title: 'Create Next App',
//   description: 'Generated by create next app',
// }

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  useEffect(() => {
    require('bootstrap/dist/js/bootstrap.bundle.min.js');
  }, []);

  return (
    <html lang="en">
      <body className={inter.className}>
        <CartProvider
                mode="payment"
                cartMode="client-only"
                // Connects to your Stripe account
                stripe={process.env.NEXT_PUBLIC_STRIPE_TEST_PUBKEY!}
                // Redirected here after successful payments
                successUrl={`${process.env.NEXT_PUBLIC_URL}/success`}
                // Redirected here when you click back on Stripe Checkout
                cancelUrl={`${process.env.NEXT_PUBLIC_URL}/?success=false`}
                currency="AUD"
                // Only customers from UK will be able to purchase
                // Having this setting means that we will capture shipping address
                // allowedCountries={["AU"]}
                // Enables local storage
                shouldPersist={true}
        >
          <APNavbar />
          {children}
          <Footer />
          {/* <DebugCart style={{left: '50%', bottom:"50%", position: "absolute", color:"red", fontWeight:"bolder"}} /> */}
        </CartProvider>
      </body>
    </html>
  )
}
