import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useShoppingCart } from "use-shopping-cart";
import styles from './ap-checkoutButton.module.css';
import { CartDetails } from "use-shopping-cart/core";

export default function CheckoutButton() {
  const [status, setStatus] = useState("idle");
  const { redirectToCheckout, cartCount, cartDetails, clearCart, loadCart, totalPrice } = useShoppingCart();

  async function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (cartCount! > 0) {
      setStatus("loading");
      try {
        
        const result = await redirectToCheckout();
        if (result?.error) {
          console.error(result);
          setStatus("redirect-error");
        }
      } catch (error) {
        console.error(error);
        setStatus("redirect-error");
      }
    } else {
      setStatus("no-items");
    }
  }

  return (
    <Row className={styles.checkoutBtn + " mb-5 mt-3 rounded-3 mx-auto"}>
      <div className="text-xs mb-3 h-5 text-center">
        {totalPrice && totalPrice < 30
          ? "You must have at least $1.00 in your basket"
          : cartCount && cartCount > 20
          ? "You cannot have more than 20 items"
          : status === "redirect-error"
          ? "Unable to redirect to Stripe checkout page"
          : status === "no-items"
          ? "Please add some items to your cart"
          : null}
      </div>
      <Button
        onClick={handleClick}
        className="py-3 px-5 rounded-md mx-auto"
        disabled={
          (totalPrice && totalPrice < 30) ||
          (cartCount && cartCount > 10) ||
          status == "no-items"
            ? true
            : false
        }
      >
        {status !== "loading" ? "Proceed to checkout" : "Loading..."}
      </Button>
    </Row>
  );
}