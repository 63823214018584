"use client";
import { Navbar, Container, Nav, Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import styles from "@/components/ap-navbar.module.css";
import Image from "next/image";
import { useShoppingCart } from "use-shopping-cart";
import ShoppingCart from "./ap-shoppingCart";
import { usePathname } from "next/navigation";

export default function APNavbar() {
  const { handleCartClick, cartDetails } = useShoppingCart();
  const pathname = usePathname();
  
  const [itemCount, setItemCount] = useState(0);
  const websiteName = "ArtfullPixels".split('');
  const letterSpacing = 12;
  const totalWidth = websiteName.length * letterSpacing;

  useEffect(() => {
    const itemCount = Object.keys(cartDetails ?? {}).reduce(
      (count, key) => count + (cartDetails![key]?.items?.length || 0),
      0
    );
    setItemCount(itemCount);
  }, [cartDetails]);

  return (
    <Navbar expand="lg" data-bs-theme="light" bg="light" sticky="top">
      <Container fluid>
        <Navbar.Brand href="/" >
            <Row>
                <Col lg={2} md={2}>
                  <Image src="/imgs/logo-smaller.png"
                    width={42} height={42} alt="logo" className="d-inline-block align-top" />
                </Col>
                <Col lg={10} md={10} className="d-none d-sm-block d-sm-none d-md-block">
                  <svg className="ms-1" width={totalWidth} height="36" style={{display:"block", textAlign:"left"}}>
                      {
                        
                        [...websiteName].map((letter, i) => (
                          <text x={i*letterSpacing} y="30" fontSize="22" fontFamily="monospace" fill={`rgb(${(12 - i)*19}, ${i*15}, ${i*5})`} style={{textAlign:"left"}} key={i}>{letter}</text>
                        ))
                      }
                  </svg>
                </Col>
          </Row>
        </Navbar.Brand>
        {
          !pathname.includes("dashboard") && (
            <div className="hstack gap-2">
              <Navbar.Toggle aria-controls="navbarScroll" className="me-auto" />
              <Nav className="ms-auto">
                <Nav.Item className="me-0 ms-0 ms-auto">
                  <Button
                    className={styles.shoppingCartBtn + " mt-2 ms-auto"}
                    onClick={() => handleCartClick()}
                  >
                    <Image
                      src="/imgs/cart.svg"
                      width={34}
                      height={34}
                      alt="shopping cart icon"
                      className="" />
                    <div className={styles.cartCount}>{itemCount}</div>
                  </Button>
                </Nav.Item>
              </Nav>
              <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    {/* <Nav.Link href="#action2">About</Nav.Link> */}
                  </Nav>
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search" />
                    <Button variant="outline-success">Search</Button>
                  </Form>
              </Navbar.Collapse>
              <ShoppingCart />
            </div>
          )
        }
      </Container>
    </Navbar>
  );
}
